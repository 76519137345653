import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const constructIdmData = (data) => {
  const allIdmTableData = data.map((val) => ({
    value: val?.id,
    // id: val?.id,
    name: val?.name ?? '',
    // description: val?.description ?? '',
    selected: val?.name === 'Doctor' ? true : false,
  }));
  return allIdmTableData;
};

export const useAllIdmRoles = create((set) => ({
  roleIDM_MasterData: [],
  roleIdmCount: 0,
  loading: false,
  // ------------- STORE METHODS ------------------
  getIdmRoles: async (
    is_doctor = true,
    search = '',
    limit = 10,
    offset = 0,
  ) => {
    try {
      set({ loading: true });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['getIdmRoles'],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/backofficeMaster/IDM_role/get/all?search=${search}&limit=${limit}&offset=${offset}&is_doctor=${is_doctor}`,
            {},
            true,
          );
          return response;
        },
      });
      let constructData =
        Array.isArray(data?.data?.rows) && data?.data?.rows?.length > 0
          ? constructIdmData(data?.data?.rows)
          : [];
      if (data?.status?.code === 200) {
        set(() => ({
          roleIDM_MasterData: constructData,
          roleIdmCount: data?.data?.count,
        }));
      }
      set(() => ({ loading: false }));
      return { data: data, constructData: constructData };
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          '❌ Some thing went wrong plesae try again!',
      );
    }
  },
}));
