import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const constructDisplayData = (data) => {
  const displayTableData = data.map((val) => ({
    value: val?.id,
    id: val?.id,
    name: val?.role_name ?? '',
    description: val?.description ?? '',
    is_active: val?.is_active ?? '',
  }));
  return displayTableData;
};

export const useDisplayRole = create((set) => ({
  displayRolesData: [],
  displayRoles_count: 0,
  loading: false,
  // ------------- STORE METHODS ------------------
  getDisplayRoles: async (search = '', limit = 10, offset = 0) => {
    try {
      set({ loading: true });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['getDisplayRoles'],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/backofficeMaster/display_master_role/get?search=${search}&limit=${limit}&offset=${offset}`,
            {},
            true
          );
          return response;
        },
      });
      if (data?.status?.code === 200) {
        set(() => ({
            displayRolesData:
            Array.isArray(data?.data?.role_data) &&
            data?.data?.role_data?.length > 0
              ? constructDisplayData(data?.data?.role_data)
              : [],
              displayRoles_count: data?.data?.role_count,
        }));
      }
      set(() => ({ loading: false }));
      return data?.data
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          '❌ Some thing went wrong plesae try again!'
      );
    }
  },
}));
