/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { queryClient } from '@hc/utils';
import { httpRequest } from '@hc/utils/axios';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const constructExpertCtaegory = (data) => {
  const arr = [];
  if (Array.isArray(data) && data?.length > 0) {
    for (const val of data) {
      const obj = {
        value: val ?? '',
        label:
          val === '1'
            ? 'Mind'
            : val === '2'
            ? 'Body'
            : val === '3'
            ? 'Food'
            : '',
      };
      arr.push(obj);
    }
  }
  return arr;
};

export const useUserManagement = create((set, get) => ({
  userManagementState: {
    expertUserData: { data: [], count: 0 },
    OthersUserData: { data: [], count: 0 },
    adminUserData: { data: [], count: 0 },
    doctorUserData: { data: [], count: 0 },
    userDataExpert: {
      id: '',
      name: '',
      email: '',
      mobile: '',
      expert_category: [],
      country_code: '',
    },
    userDataExpertError: {
      name: '',
      email: '',
      mobile: '',
      expert_category: '',
      country_code: '',
    },
    userDataOthers: {
      id: '',
      name: '',
      email: '',
      mobile: '',
      category_id: 0,
      othersIdm_category: [],
      country_code: '',
    },
    userDataOthersError: {
      name: '',
      email: '',
      mobile: '',
      category_id: '',
      country_code: '',
      othersIdm_category: '',
    },
    userDataDoctor: {
      id: '',
      doctor_user_id: '',
      name: '',
      email: '',
      mobile: '',
      country_code: '',
      is_health_circles_doctor: false,
      is_chief_doctor: false,
      register_no: '',
      state_medical_council_name: '',
      year_of_registration: '',
      Idm_category: [],
    },
    userDataDoctorError: {
      name: '',
      email: '',
      mobile: '',
      country_code: '',
      register_no: '',
      state_medical_council_name: '',
      year_of_registration: '',
      Idm_category: '',
    },
    appointmentPlanManagementData: [],
    followupPlanManagementData: [],
    chatPlanManagementData: [],
  },
  loading: null,
  selectBoxIsLoading: null,

  // Get expert user list
  getExpertUser: async (payload) => {
    try {
      const { userManagementState } = get();
      set({ loading: true });
      // Hitting the ExpertUserGet API

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['expertUserList'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/users/expert/get`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status?.code === 200) {
        const arr = [];
        if (Array.isArray(data?.results) && data?.results?.length > 0) {
          for (const val of data?.results) {
            const obj = {
              createdAt: val?.created_at ?? '',
              email: val?.email_id ?? '',
              fullName: val?.first_name ?? '',
              id: val?.id ?? '',
              userId: val?.user_id,
              isActive: val?.is_verified ?? '',
              phoneNumber: val?.mobile ?? '',
              mobile_no: val?.mobile_no ?? '',
              mobile_no_country_code: val?.mobile_no_country_code ?? '',
              role_id: val?.role_id ?? '',
              status: val?.status ?? '',
              statusId: val?.status_id ?? '',
              updatedAt: val?.updated_at ?? '',
              user_profile_id: val?.user_profile_id ?? '',
              expert_category:
                Array.isArray(val?.expert_category) &&
                val?.expert_category?.length > 0
                  ? constructExpertCtaegory(val?.expert_category)
                  : [],
              expert_category_data: val?.expert_category ?? [],
            };
            arr.push(obj);
          }
        }
        set(() => ({
          loading: false,
          userManagementState: {
            ...userManagementState,
            expertUserData: { data: arr ?? [], count: data?.expert_count ?? 0 },
          },
        }));
      }

      return set({ loading: false });
    } catch (err) {
      set({ loading: false });

      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getOthersUser: async (payload) => {
    try {
      const { userManagementState } = get();
      set({ loading: true });
      // Hitting the OthersUserGet API

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['getOthersUser'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/users/other/get`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status?.code === 200) {
        const arr = [];
        if (Array.isArray(data?.results) && data?.results?.length > 0) {
          for (const val of data?.results) {
            const obj = {
              createdAt: val?.created_at ?? '',
              email: val?.email_id ?? '',
              fullName: val?.first_name ?? '',
              id: val?.id ?? '',
              userId: val?.user_id,
              isActive: val?.is_verified ?? '',
              phoneNumber: val?.mobile ?? '',
              mobile_no: val?.mobile_no ?? '',
              mobile_no_country_code: val?.mobile_no_country_code ?? '',
              role_id: val?.role_id ?? '',
              status: val?.status ?? '',
              statusId: val?.status_id ?? '',
              updatedAt: val?.updated_at ?? '',
              user_profile_id: val?.user_profile_id ?? '',
              idm_role_id: val?.idm_role_id,
              idm_role: val?.idm_role,
              role_name: val?.role_name,
            };
            arr.push(obj);
          }
        }
        set(() => ({
          loading: false,
          userManagementState: {
            ...userManagementState,
            OthersUserData: { data: arr ?? [], count: data?.other_count ?? 0 },
          },
        }));
      }

      return set({ loading: false });
    } catch (err) {
      set({ loading: false });

      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  // Get admin user list
  getAdminUser: async (payload) => {
    try {
      const { userManagementState } = get();
      set({ loading: true });
      // Hitting the ExpertUserGet API

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['adminUserList'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/backoffice/users/admin/get?user_id=${
              payload?.user_id
            }&search=${payload?.search ?? ''}&limit=${
              payload?.limit ?? 10
            }&offset=${payload?.offset ?? 0}`,
            {},
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status?.code === 200) {
        const arr = [];
        if (Array.isArray(data?.results) && data?.results?.length > 0) {
          for (const val of data?.results) {
            const obj = {
              createdAt: val?.created_at ?? '',
              email: val?.email_id ?? '',
              fullName: val?.first_name ?? '',
              id: val?.id ?? '',
              userId: val?.user_id,
              isActive: val?.is_verified ?? '',
              phoneNumber: val?.mobile ?? '',
              role_id: val?.role_id ?? '',
              status: val?.status ?? '',
              statusId: val?.status_id ?? '',
              updatedAt: val?.updated_at ?? '',
              user_profile_id: val?.user_profile_id ?? '',
            };
            arr.push(obj);
          }
        }
        set(() => ({
          loading: false,
          userManagementState: {
            ...userManagementState,
            adminUserData: { data: arr ?? [], count: data?.admin_count ?? 0 },
          },
        }));
      }

      return set({ loading: false });
    } catch (err) {
      set({ loading: false });

      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  // Get doctor user list
  getDoctorUser: async (payload) => {
    try {
      const { userManagementState } = get();
      set({ loading: true });
      // Hitting the ExpertUserGet API

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['doctorUserList'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/backoffice/users/doctors/get?user_id=${
              payload?.user_id
            }&search=${payload?.search ?? ''}&limit=${
              payload?.limit ?? 10
            }&offset=${payload?.offset ?? 0}`,
            {},
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status?.code === 200) {
        const arr = [];
        if (Array.isArray(data?.results) && data?.results?.length > 0) {
          for (const val of data?.results) {
            const obj = {
              createdAt: val?.created_at ?? '',
              email: val?.email_id ?? '',
              fullName: val?.first_name ?? '',
              id: val?.id ?? '',
              userId: val?.user_id,
              isActive: val?.is_verified ?? '',
              phoneNumber: val?.mobile ?? '',
              mobile_no: val?.mobile_no ?? '',
              mobile_no_country_code: val?.mobile_no_country_code ?? '',
              role_id: val?.role_id ?? '',
              status: val?.status ?? '',
              statusId: val?.status_id ?? '',
              statusIdd: val?.status_id ?? '',
              updatedAt: val?.updated_at ?? '',
              user_profile_id: val?.user_profile_id ?? '',
              appoinment_plan_id: val?.appoinment_plan_id ?? '',
              followup_plan_id: val?.followup_plan_id ?? '',
              chat_plan_id: val?.chat_plan_id ?? '',
              is_health_circles_doctor: val?.is_health_circles_doctor ?? false,
              is_chief_doctor: val?.role_id === 7 ? true : false,
              profile_pic: val?.profile_pic ?? '',
              register_no: val?.register_no ?? '',
              state_medical_council_name: val?.state_medical_council_name ?? '',
              year_of_registration: val?.year_of_registration ?? '',
              idm_role_id: val?.idm_role_id,
              idm_role: val?.idm_role,
              // Idm_category: '',
            };
            arr.push(obj);
          }
        }
        set(() => ({
          loading: false,
          userManagementState: {
            ...userManagementState,
            doctorUserData: { data: arr ?? [], count: data?.doctor_count ?? 0 },
          },
        }));
      }

      return set({ loading: false });
    } catch (err) {
      set({ loading: false });

      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  // Get plan management list
  getPlanManagementData: async () => {
    try {
      const { userManagementState } = get();
      set({ selectBoxIsLoading: true });
      // Hitting the ExpertUserGet API

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['getPlanManagementData'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/get-plan-management-by-category`,
            {},
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status?.code === 200) {
        const appointmentArr = [];
        const followupArr = [];
        const chatArr = [];
        if (Array.isArray(data) && data?.length > 0) {
          for (const val of data) {
            if (val?.plan_type === 'Appointment') {
              const obj = {
                name: `${val?.plan_name}(₹${val?.amount_in_rupees}/-)`,
                label: `${val?.plan_name}(₹${val?.amount_in_rupees}/-)`,
                value: val?.id,
              };
              appointmentArr.push(obj);
            } else if (val?.plan_type === 'Followup') {
              const obj = {
                name: `${val?.plan_name}(₹${val?.amount_in_rupees}/-)`,
                label: `${val?.plan_name}(₹${val?.amount_in_rupees}/-)`,
                value: val?.id,
              };
              followupArr.push(obj);
            } else if (val?.plan_type === 'Chat') {
              const obj = {
                name: `${val?.plan_name}(₹${val?.amount_in_rupees}/-)`,
                label: `${val?.plan_name}(₹${val?.amount_in_rupees}/-)`,
                value: val?.id,
              };
              chatArr.push(obj);
            }
          }
        }
        set(() => ({
          selectBoxIsLoading: false,
          userManagementState: {
            ...userManagementState,
            appointmentPlanManagementData: appointmentArr ?? [],
            followupPlanManagementData: followupArr ?? [],
            chatPlanManagementData: chatArr ?? [],
          },
        }));
      }

      return set({ selectBoxIsLoading: false });
    } catch (err) {
      set({ selectBoxIsLoading: false });

      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  // Upsert expert user

  expertUserUpsert: async () => {
    try {
      const { userManagementState } = get();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      const payload = {
        user_id: tokenData?.id ?? '',
        name: userManagementState?.userDataExpert?.name ?? '',
        mobile_no: userManagementState?.userDataExpert?.mobile ?? '',
        country_code: userManagementState?.userDataExpert?.country_code ?? '',
        email_id: userManagementState?.userDataExpert?.email ?? '',
        expert_category:
          userManagementState?.userDataExpert?.expert_category ?? '',
      };
      set({ loading: true });
      // Hitting the ExpertUser Upsert API
      queryClient.invalidateQueries({
        queryKey: ['expertUserInvite'],
      });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['expertUserInvite'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.auth_url}/user/expert/invite`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        toast.success(status?.message);
      }

      return status;
    } catch (err) {
      set({ loading: false });

      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  expertUserUpdate: async () => {
    try {
      const { userManagementState } = get();
      const { userDataExpert } = userManagementState;
      const payload = {
        user_profile_id: userDataExpert?.id ?? '',
        name: userDataExpert?.name ?? '',
      };

      set({ loading: true });
      // Hitting the ExpertUser Upsert API
      queryClient.invalidateQueries({
        queryKey: ['expertUserUpdate'],
      });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['expertUserUpdate'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/users/update`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status?.code === 200) {
        toast.success(status?.message);
      }

      return status;
    } catch (err) {
      set({ loading: false });

      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  othersUserUpsert: async () => {
    try {
      const { userManagementState } = get();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      const payload = {
        user_id: tokenData?.id ?? '',
        name: userManagementState?.userDataOthers?.name ?? '',
        mobile_no: userManagementState?.userDataOthers?.mobile ?? '',
        country_code: userManagementState?.userDataOthers?.country_code ?? '',
        email_id: userManagementState?.userDataOthers?.email ?? '',
        role: userManagementState?.userDataOthers?.category_id,
        idm_role_id:
          userManagementState?.userDataOthers?.othersIdm_category ?? '',
      };
      set({ loading: true });
      // Hitting the ExpertUser Upsert API
      queryClient.invalidateQueries({
        queryKey: ['othersUserUpsert'],
      });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['othersUserUpsert'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.auth_url}/user/other_users/invite`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        toast.success(status?.message);
      }

      return status;
    } catch (err) {
      set({ loading: false });

      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  OthersUserUpdate: async () => {
    try {
      const { userManagementState } = get();
      const { userDataOthers } = userManagementState;
      const payload = {
        user_profile_id: userDataOthers?.id ?? '',
        name: userDataOthers?.name ?? '',
        idm_role_id: userDataOthers?.othersIdm_category,
      };

      set({ loading: true });
      // Hitting the ExpertUser Upsert API
      queryClient.invalidateQueries({
        queryKey: ['OthersUserUpdate'],
      });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['OthersUserUpdate'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/users/update`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status?.code === 200) {
        toast.success(status?.message);
      }

      return status;
    } catch (err) {
      set({ loading: false });

      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  // Upsert doctor  user

  doctorUserUpsert: async () => {
    try {
      const { userManagementState } = get();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      const payload = {
        user_id: tokenData?.id ?? '',
        name: userManagementState?.userDataDoctor?.name ?? '',
        mobile_no: userManagementState?.userDataDoctor?.mobile ?? '',
        country_code: userManagementState?.userDataDoctor?.country_code ?? '',
        email_id: userManagementState?.userDataDoctor?.email ?? '',
        is_health_circles_doctor:
          userManagementState?.userDataDoctor?.is_health_circles_doctor ??
          false,
        is_chief_doctor:
          userManagementState?.userDataDoctor?.is_chief_doctor ?? false,
        register_no: userManagementState?.userDataDoctor?.register_no ?? '',
        state_medical_council_name:
          userManagementState?.userDataDoctor?.state_medical_council_name ?? '',
        year_of_registration:
          userManagementState?.userDataDoctor?.year_of_registration ?? '',
        idm_role_id: userManagementState?.userDataDoctor?.Idm_category,
      };

      set({ loading: true });
      // Hitting the ExpertUser Upsert API
      queryClient.invalidateQueries({
        queryKey: ['doctorUserUpsert'],
      });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['doctorUserUpsert'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.auth_url}/user/doctor/invite`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status?.code === 200) {
        toast.success(status?.message);
      }

      return status;
    } catch (err) {
      set({ loading: false });

      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  doctorUserUpdate: async () => {
    try {
      const { userManagementState } = get();
      const { userDataDoctor } = userManagementState;
      const payload = {
        user_profile_id: userDataDoctor?.id ?? '',
        name: userDataDoctor?.name ?? '',
        idm_role_id: userDataDoctor?.Idm_category,
      };

      set({ loading: true });
      // Hitting the ExpertUser Upsert API
      queryClient.invalidateQueries({
        queryKey: ['doctorUserUpdate'],
      });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['doctorUserUpdate'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/users/update`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status?.code === 200) {
        toast.success(status?.message);
      }

      return status;
    } catch (err) {
      set({ loading: false });

      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  doctorApprove: async () => {
    try {
      const { userManagementState } = get();
      const { userDataDoctor } = userManagementState;
      set({ loading: true });
      const { data } = await httpRequest(
        'post',
        `${envConfig.api_url}/backoffice/doctor/approved-status/update`,
        {
          user_id: userDataDoctor?.doctor_user_id,
          name: userDataDoctor?.name,
          register_no: userDataDoctor?.register_no,
          state_medical_council_name:
            userDataDoctor?.state_medical_council_name,
          year_of_registration: userDataDoctor?.year_of_registration,
        },
        true,
      );
      if (data?.status?.code === 200) {
        toast.success(data?.status?.message ?? 'Doctor Approved Successfully');
      }
      set({ loading: false });
      return data?.status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  // upsert expert user

  userStatusUpadte: async (row, value) => {
    try {
      const payload = {
        user_id: row?.userId,
        is_verified: value,
      };
      set({ loading: true });
      // Hitting the ExpertUser Upsert API

      const { statusCode, message } = await queryClient.fetchQuery({
        queryKey: ['userVerify'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.auth_url_v1}/user/update_status`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      // if (statusCode === '200') {
      //   toast.success(message);
      // }

      return statusCode;
    } catch (err) {
      set({ loading: false });

      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  userStatusOthers: async (row, value) => {
    try {
      const payload = {
        user_id: row?.userId,
        is_verified: value,
      };
      set({ loading: true });

      const {data ,status } = await queryClient.fetchQuery({
        queryKey: ['otherverify'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/other/approved-status/update`,
            {
              ...payload,
            },
            true,
          );
          return response;
        },
        staleTime: 120000,
      });

      // if (statusCode === '200') {
      //   toast.success(message);
      // }

      return status;
    } catch (err) {
      set({ loading: false });

      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  doctorPlanUpdate: async (payload) => {
    try {
      set({ loading: true });
      // Hitting the ExpertUser Upsert API

      const { status } = await queryClient.fetchQuery({
        queryKey: ['doctorPlanUpdate'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/doctor/plan-amount/update`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      return status;
    } catch (err) {
      set({ loading: false });

      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  // Doctor resend Invite
  resendInvite: async (payload) => {
    try {
      set({ loading: true });
      // Hitting the ExpertUser Upsert API

      const { statusCode, message } = await queryClient.fetchQuery({
        queryKey: ['doctorResendInvite'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.auth_url_v1}/resent_invite`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      return statusCode;
    } catch (err) {
      set({ loading: false });

      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  userActiveBlockUpdate: async (row, value) => {
    try {
      const payload = {
        user_id: row?.userId,
        status: value,
      };
      set({ loading: true });
      // Hitting the ExpertUser Upsert API

      const { statusCode, message } = await queryClient.fetchQuery({
        queryKey: ['userVerify'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.auth_url_v1}/user/update_status`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      // if (statusCode === '200') {
      //   toast.success(message);
      // }

      return statusCode;
    } catch (err) {
      set({ loading: false });

      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  // User data state update

  updateUserData: (key, value) => {
    const { userManagementState } = get();
    const { userDataExpert } = userManagementState;
    if (key === 'name' || key === 'email' || key === 'expert_category') {
      set({
        userManagementState: {
          ...userManagementState,
          userDataExpert: {
            ...userDataExpert,
            [key]: value,
          },
        },
      });
    } else if (key === 'mobileNumber') {
      set({
        userManagementState: {
          ...userManagementState,
          userDataExpert: {
            ...userDataExpert,
            mobile: value?.mobile ?? '',
            country_code: value?.mobileCode ?? '',
          },
        },
      });
    }
  },

  updateUserOthers: (key, value) => {
    const { userManagementState } = get();
    const { userDataOthers } = userManagementState;
    if (
      key === 'name' ||
      key === 'email' ||
      key === 'othersIdm_category' ||
      key === 'category_id'
    ) {
      set({
        userManagementState: {
          ...userManagementState,
          userDataOthers: {
            ...userDataOthers,
            [key]: value,
          },
        },
      });
    } else if (key === 'mobileNumber') {
      set({
        userManagementState: {
          ...userManagementState,
          userDataOthers: {
            ...userDataOthers,
            mobile: value?.mobile ?? '',
            country_code: value?.mobileCode ?? '',
          },
        },
      });
    }
  },

  updateUserErrorOthers: (data) => {
    const { userManagementState } = get();
    const { userDataOthersError } = userManagementState;
    set({
      userManagementState: {
        ...userManagementState,
        userDataOthersError: {
          ...userDataOthersError,
          id: data?.name,
          name: data?.name ?? '',
          email: data?.email ?? '',
          mobile: data?.mobile ?? '',
          othersIdm_category: data?.othersIdm_category ?? '',
          category_id: data?.category_id ?? '',
        },
      },
    });
  },

  updateDoctorUserData: (key, value) => {
    const { userManagementState } = get();
    const { userDataDoctor } = userManagementState;
    if (
      key === 'name' ||
      key === 'email' ||
      key === 'register_no' ||
      key === 'state_medical_council_name' ||
      key === 'year_of_registration' ||
      key === 'Idm_category'
    ) {
      set({
        userManagementState: {
          ...userManagementState,
          userDataDoctor: {
            ...userDataDoctor,
            [key]: value,
          },
        },
      });
    } else if (key === 'is_health_circles_doctor') {
      set({
        userManagementState: {
          ...userManagementState,
          userDataDoctor: {
            ...userDataDoctor,
            [key]: value,
            is_chief_doctor: false,
          },
        },
      });
    } else if (key === 'is_chief_doctor') {
      set({
        userManagementState: {
          ...userManagementState,
          userDataDoctor: {
            ...userDataDoctor,
            [key]: value,
            is_health_circles_doctor: false,
          },
        },
      });
    } else if (key === 'mobileNumber') {
      set({
        userManagementState: {
          ...userManagementState,
          userDataDoctor: {
            ...userDataDoctor,
            mobile: value?.mobile ?? '',
            country_code: value?.mobileCode ?? '',
          },
        },
      });
    }
  },

  updateUserError: (data) => {
    const { userManagementState } = get();
    const { userDataExpertError } = userManagementState;
    set({
      userManagementState: {
        ...userManagementState,
        userDataExpertError: {
          ...userDataExpertError,
          name: data?.name ?? '',
          email: data?.email ?? '',
          mobile: data?.mobile ?? '',
          expert_category: data?.expert_category ?? '',
          country_code: data?.country_code ?? '',
        },
      },
    });
  },

  updateDoctorUserError: (data) => {
    const { userManagementState } = get();
    const { userDataDoctorError } = userManagementState;
    set({
      userManagementState: {
        ...userManagementState,
        userDataDoctorError: {
          ...userDataDoctorError,
          name: data?.name ?? '',
          email: data?.email ?? '',
          mobile: data?.mobile ?? '',
          country_code: data?.country_code ?? '',
          register_no: data?.register_no ?? '',
          state_medical_council_name: data?.state_medical_council_name ?? '',
          year_of_registration: data?.year_of_registration ?? '',
          Idm_category: data?.Idm_category ?? '',
        },
      },
    });
  },

  clearUserData: () => {
    const { userManagementState } = get();
    set(() => ({
      loading: false,
      userManagementState: {
        ...userManagementState,
        userDataExpert: {
          id: '',
          name: '',
          email: '',
          mobile: '',
          expert_category: [],
          country_code: '',
        },
        userDataExpertError: {
          name: '',
          email: '',
          mobile: '',
          expert_category: '',
          country_code: '',
        },
      },
    }));
  },

  clearUserOthersData: () => {
    const { userManagementState } = get();
    set(() => ({
      loading: false,
      userManagementState: {
        ...userManagementState,
        userDataOthers: {
          id: '',
          name: '',
          email: '',
          mobile: '',
          category_id: 0,
          othersIdm_category: [],
          country_code: '',
        },
        userDataOthersError: {
          name: '',
          email: '',
          mobile: '',
          category_id: '',
          country_code: '',
          othersIdm_category: '',
        },
      },
    }));
  },

  clearDoctorUserData: () => {
    const { userManagementState } = get();
    set(() => ({
      loading: false,
      userManagementState: {
        ...userManagementState,
        userDataDoctor: {
          id: '',
          doctor_user_id: '',
          name: '',
          email: '',
          mobile: '',
          country_code: '',
          is_health_circles_doctor: false,
          is_chief_doctor: false,
          register_no: '',
          state_medical_council_name: '',
          year_of_registration: '',
          Idm_category: [],
        },
        userDataDoctorError: {
          name: '',
          email: '',
          mobile: '',
          country_code: '',
          Idm_category: '',
        },
      },
    }));
  },

  initialEditDoctorData: (data) => {
    const { userManagementState } = get();
    set({
      userManagementState: {
        ...userManagementState,
        userDataDoctor: {
          id: data?.user_profile_id || '',
          doctor_user_id: data?.userId || '',
          name: data?.fullName ?? '',
          email: data?.email ?? '',
          mobile: data?.mobile_no ?? '',
          country_code: data?.mobile_no_country_code ?? '',
          is_health_circles_doctor: data?.is_health_circles_doctor ?? false,
          is_chief_doctor: data?.is_chief_doctor ?? false,
          register_no: data?.register_no ?? '',
          state_medical_council_name: data?.state_medical_council_name ?? '',
          year_of_registration: data?.year_of_registration ?? '',
          Idm_category: data?.idm_role_id ?? [],
        },
        userDataDoctorError: {
          name: '',
          email: '',
          mobile: '',
          country_code: '',
          Idm_category: '',
        },
      },
    });
  },

  initialEditExpertData: (data) => {
    const { userManagementState } = get();
    set({
      userManagementState: {
        ...userManagementState,
        userDataExpert: {
          id: data?.user_profile_id || '',
          name: data?.fullName ?? '',
          email: data?.email ?? '',
          mobile: data?.mobile_no ?? '',
          country_code: data?.mobile_no_country_code ?? '',
          expert_category: data?.expert_category_data ?? [],
        },
        userDataExpertError: {
          name: '',
          email: '',
          mobile: '',
          expert_category: '',
          country_code: '',
        },
      },
    });
    const arra = [
      {
        value: '1',
        name: 'Mind',
        selected: false,
      },
      {
        value: '2',
        name: 'Body',
        selected: false,
      },
      {
        value: '3',
        name: 'Food',
        selected: false,
      },
    ];
    if (data?.expert_category_data?.length > 0) {
      const arr = [];
      // eslint-disable-next-line array-callback-return
      arra.map((v) => {
        const selectedCheck = data?.expert_category_data.find(
          (va) => va === v.value,
        );
        arr.push({ ...v, selected: selectedCheck ? true : false });
      });
      return arr;
    }
    return arra;
  },

  initialEditOtherstData: (data) => {
    const { userManagementState } = get();
    set({
      userManagementState: {
        ...userManagementState,
        userDataOthers: {
          id: data?.user_profile_id || '',
          name: data?.fullName ?? '',
          email: data?.email ?? '',
          mobile: data?.mobile_no ?? '',
          country_code: data?.mobile_no_country_code ?? '',
          othersIdm_category: data?.idm_role_id ?? [],
          category_id: data?.role_id,
        },
        userDataOtherstError: {
          name: '',
          email: '',
          mobile: '',
          expert_category: '',
          country_code: '',
          category_id: '',
          othersIdm_category: '',
        },
      },
    });
  },
}));
