/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-children-prop */
import { getDateFormatToString } from '@hc/dayjs';
import {
  useAllIdmRoles,
  useDisplayRole,
  useOrganization,
  useUserManagement
} from '@hc/store';
import {
  CheckBox,
  Dropdown,
  EditIcon,
  HealthCirclesLogo,
  Input,
  Label,
  MobileInput,
  MultiSelectToggleButton,
  RefreshIcon,
  Switch,
  ToggleButton
} from '@hc/ui/atoms';
import {
  Pagination,
  TableSkeletonLoader,
  UserManagementDrawer,
  UserManagementTopContainer
} from '@hc/ui/components';
import { MasterTable } from '@hc/ui/components/backoffice';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import {
  Avatar,
  Badge,
  Box,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import toast from 'react-hot-toast';
import { version } from '../../../package.json';
import { user_management_style } from './style';

export default function UserManagement(props) {
  const { className = '', ...rest } = props;
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);
  const [othersDrawerOpen, setOthersDrawerOpen] = useState(false);
  const [expertDrawerOpen, setExpertDrawerOpen] = useState(false);
  const [doctorDrawerOpen, setDoctorDrawerOpen] = useState(false);
  const [doctorsaveBtn, setdoctorsaveBtn] = useState(false);
  const [expertsaveBtn, setexpertsaveBtn] = useState(false);
  const [otherSaveBtn, setotherSaveBtn] = useState(false);

  const {
    getExpertUser,
    // getAdminUser,
    getDoctorUser,
    userManagementState,
    updateUserData,
    updateDoctorUserData,
    expertUserUpsert,
    expertUserUpdate,
    doctorUserUpsert,
    doctorUserUpdate,
    updateUserError,
    updateDoctorUserError,
    clearUserData,
    clearDoctorUserData,
    userStatusUpadte,
    userActiveBlockUpdate,
    // resendInvite,
    selectBoxIsLoading,
    getPlanManagementData,
    initialEditDoctorData,
    initialEditExpertData,
    initialEditOtherstData,
    doctorPlanUpdate,
    doctorApprove,
    updateUserOthers,
    clearUserOthersData,
    updateUserErrorOthers,
    getOthersUser,
    OthersUserUpdate,
    othersUserUpsert,
    userStatusOthers,
    loading,
  } = useUserManagement(
    (state) => ({
      userStatusUpadte: state.userStatusUpadte,
      userStatusOthers:state.userStatusOthers,
      userActiveBlockUpdate: state.userActiveBlockUpdate,
      clearUserData: state.clearUserData,
      clearDoctorUserData: state.clearDoctorUserData,
      expertUserUpsert: state.expertUserUpsert,
      expertUserUpdate: state.expertUserUpdate,
      doctorUserUpsert: state.doctorUserUpsert,
      doctorUserUpdate: state.doctorUserUpdate,
      updateUserError: state.updateUserError,
      updateDoctorUserError: state.updateDoctorUserError,
      updateUserData: state.updateUserData,
      updateDoctorUserData: state.updateDoctorUserData,
      getExpertUser: state.getExpertUser,
      getOthersUser: state.getOthersUser,
      getAdminUser: state.getAdminUser,
      getDoctorUser: state.getDoctorUser,
      loading: state.Floading,
      userManagementState: state.userManagementState,
      // resendInvite: state.resendInvite,
      selectBoxIsLoading: state.selectBoxIsLoading,
      getPlanManagementData: state.getPlanManagementData,
      initialEditDoctorData: state.initialEditDoctorData,
      initialEditExpertData: state.initialEditExpertData,
      doctorPlanUpdate: state.doctorPlanUpdate,
      doctorApprove: state.doctorApprove,
      updateUserOthers: state.updateUserOthers,
      initialEditOtherstData: state.initialEditOtherstData,
      clearUserOthersData: state.clearUserOthersData,
      updateUserErrorOthers: state.updateUserErrorOthers,
      OthersUserUpdate: state.OthersUserUpdate,
      othersUserUpsert: state.othersUserUpsert,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { getIdmRoles, roleIDM_MasterData } = useAllIdmRoles((state) => ({
    getIdmRoles: state.getIdmRoles,
    roleIDM_MasterData: state.roleIDM_MasterData,
  }));

  const { getDisplayRoles, displayRolesData } = useDisplayRole((state) => ({
    getDisplayRoles: state.getDisplayRoles,
    displayRolesData: state.displayRolesData,
  }));

  const { resendInvite } = useOrganization((state) => ({
    resendInvite: state.resendInvite,
  }));

  const {
    expertUserData,
    OthersUserData,
    doctorUserData,
    // adminUserData,
    userDataOthers,
    userDataOthersError,
    userDataExpert,
    userDataExpertError,
    userDataDoctor,
    userDataDoctorError,
    followupPlanManagementData,
    appointmentPlanManagementData,
    chatPlanManagementData,
  } = userManagementState;

  const statusColor = {
    1: '#25C460',
    2: '#aeaeae',
    3: '#fd5b5b',
    4: '#F69D5B',
  };

  const expertTypeData = [
    {
      value: '1',
      name: 'Mind',
      selected: false,
    },

    {
      value: '2',
      name: 'Body',
      selected: false,
    },
    {
      value: '3',
      name: 'Food',
      selected: false,
    },
  ];


  const [buttons, setButtons] = useState(expertTypeData);
  const [roleIdm, setRoleIdm] = useState([]);
  const [roleIdmOthers, setRoleIdmOthers] = useState([]);
  const [displayRole, setDisplayRole] = useState([]);
  const [tab, setTab] = useState(0);

  // // For admin
  // const [adminOffset, setAdminOffset] = useState(0);
  // const [adminRowsPerPage, setAdminRowsPerPage] = React.useState(10);
  // const [adminSearch, setAdminSearch] = useState('');

  // For doctor
  const [doctorOffset, setDoctorOffset] = useState(0);
  const [doctorRowsPerPage, setDoctorRowsPerPage] = React.useState(10);
  const [doctorSearch, setDoctorSearch] = useState('');
  const [isApprove, setIsApprove] = useState(false);

  // For expert
  const [expertOffset, setExpertOffset] = useState(0);
  const [expertRowsPerPage, setExpertRowsPerPage] = React.useState(10);
  const [expertSearch, setExpertSearch] = useState('');

  // For Others
  const [othersOffset, setOthersOffset] = useState(0);
  const [othersRowsPerPage, setOthersRowsPerPage] = React.useState(10);
  const [othersSearch, setOthersSearch] = useState('');

  const clearIdmModal = async () => {
    const clear = await getIdmRoles();
    if (clear?.status?.code === 200 && tab === 0) {
      setRoleIdm([]);
    } else if (tab === 2) {
      setRoleIdmOthers([]);
      setDisplayRole([]);
    }
  };

  // Close doctor drawer & clear state
  const closeDoctorDrawer = () => {
    setDoctorDrawerOpen(false);
    clearDoctorUserData();
    clearIdmModal();
    setdoctorsaveBtn(false);
  };

  // Close expert drawer & clear state
  const closeExpertDrawer = () => {
    setExpertDrawerOpen(false);
    clearUserData();
    setButtons(expertTypeData);
    setexpertsaveBtn(false);
  };

  const closeOthersDrawer = () => {
    setOthersDrawerOpen(false);
    clearUserOthersData();
    clearIdmModal();
    setotherSaveBtn(false);
  };

  const handleButton = (buttonId) => {
    const newButtons = buttons.map((btn) => {
      if (btn.value !== buttonId) return btn;
      btn.selected = !btn.selected;
      return btn;
    });
    setButtons(newButtons);

    const arr = [];

    for (const val of newButtons) {
      let id = '';
      if (val?.selected === true) {
        id = val?.value;
        arr.push(id);
      }
    }

    updateUserData('expert_category', arr);
  };

  const handleIDMButton = (buttonId, data) => {
    if (data?.name === 'Doctor') {
      const newButtons = roleIdm.map((btn) => {
        if (btn.value === buttonId) {
          btn.selected = true;
        }
        return btn;
      });
      setRoleIdm(newButtons);
      const selectedIds = newButtons
        .filter((val) => val.selected)
        .map((val) => val.value);

      updateDoctorUserData('Idm_category', selectedIds);
      return;
    }
    const newButtons = roleIdm.map((btn) => {
      if (btn.value !== buttonId) return btn;
      btn.selected = !btn.selected;
      return btn;
    });

    setRoleIdm(newButtons);
    const selectedIds = newButtons
      .filter((val) => val.selected)
      .map((val) => val.value);

    updateDoctorUserData('Idm_category', selectedIds);
  };



  const handleOtherIDMButton = (buttonId) => {
    const newButtons = roleIdmOthers.map((btn) => {
      if (btn.value !== buttonId) return btn;
      btn.selected = !btn.selected;
      return btn;
    });

    setRoleIdmOthers(newButtons);
    const selectedIds = newButtons
      .filter((val) => val.selected)
      .map((val) => val.value);

    updateUserOthers('othersIdm_category', selectedIds);
  };

  const handleAddClick = async () => {
    if (tab === 0) {
      clearDoctorUserData();
      const res = await getIdmRoles(true);
      setRoleIdm(res?.constructData); 
      updateDoctorUserData(
        'Idm_category',
        res?.constructData
        ?.filter((val) => val?.selected === true)
        ?.map((val) => val?.value)
      )
      setDoctorDrawerOpen(true);
    } else if (tab === 1) {
      clearUserData();
      setExpertDrawerOpen(true);
    } else if (tab === 2) {
      clearUserOthersData();
      const res = await getIdmRoles(false);
      setRoleIdmOthers(res?.constructData);
      // updateUserOthers(
      //   'othersIdm_category',
      //   res?.constructData
      //   ?.filter((val) => val?.selected === true)
      //   ?.map((val) => val?.value)
      // );
      setDisplayRole(displayRolesData);
      setOthersDrawerOpen(true);
    }
  };


  // const initialAdminUserGetData = async () => {
  //   const obj = {
  //     user_id: data?.id,
  //     search: adminSearch ?? '',
  //     limit: adminRowsPerPage ?? 10,
  //     offset: adminOffset ?? 0,
  //   };
  //   await getAdminUser(obj);
  // };

  const initialDoctorUserGetData = async () => {
    const obj = {
      user_id: data?.id,
      search: doctorSearch ?? '',
      limit: doctorRowsPerPage ?? 10,
      offset: doctorOffset ?? 0,
    };
    await getDoctorUser(obj);
  };

  const initialExpertUserGetData = async () => {
    const obj = {
      user_id: data?.id,
      search: expertSearch ?? '',
      limit: expertRowsPerPage ?? 10,
      offset: expertOffset ?? 0,
    };
    await getExpertUser(obj);
  };

  const initialOthersUserGetData = async () => {
    const obj = {
      user_id: data?.id,
      search: expertSearch ?? '',
      limit: expertRowsPerPage ?? 10,
      offset: expertOffset ?? 0,
    };
    await getOthersUser(obj);
  };
  // VALIDATE EXPERT UPSERT DRAWER
  const isIamValidToSave = () => {
    const userDataExpertCopy = JSON.parse(JSON.stringify(userDataExpert));
    const errorCopy = JSON.parse(JSON.stringify(userDataExpertError));
    let isValid = true;

    // checking name
    if (!userDataExpertCopy?.name) {
      isValid = false;
      errorCopy.name = 'Please enter the name';
    } else {
      errorCopy.name = '';
    }

    // checking email
    const filter = /\S+@\S+\.\S+/;
    if (userDataExpertCopy?.email?.length === 0) {
      isValid = false;
      errorCopy.email = 'Please enter the mail';
    } else if (
      userDataExpertCopy?.email?.length > 0 &&
      !filter.test(userDataExpertCopy?.email)
    ) {
      isValid = false;
      errorCopy.email = 'Please enter the valid mail';
    } else {
      errorCopy.email = '';
    }

    // Checking Mobile Number
    if (userDataExpertCopy?.mobile.length === 0) {
      isValid = false;
      errorCopy.mobile = 'Enter a valid 10 digit mobile number';
    } else if (userDataExpertCopy?.mobile.length < 9) {
      isValid = false;
      errorCopy.mobile = 'Enter a valid 10 digit mobile number';
    } else {
      errorCopy.mobile = '';
    }
    // Checking expert category
    if (userDataExpertCopy?.expert_category?.length === 0) {
      isValid = false;
      errorCopy.expert_category = 'Please select the expert category';
    } else {
      errorCopy.expert_category = '';
    }

    updateUserError(errorCopy);
    return isValid;
  };

  // VALIDATE others UPSERT DRAWER
  const isIamValidToSaveOthers = () => {
    const userDataOthersCopy = JSON.parse(JSON.stringify(userDataOthers));
    const errorCopy = JSON.parse(JSON.stringify(userDataOthersError));
    let isValid = true;

    // checking name
    if (!userDataOthersCopy?.name) {
      isValid = false;
      errorCopy.name = 'Please enter the name';
    } else {
      errorCopy.name = '';
    }

    // checking email
    const filter = /\S+@\S+\.\S+/;
    if (userDataOthersCopy?.email?.length === 0) {
      isValid = false;
      errorCopy.email = 'Please enter the mail';
    } else if (
      userDataOthersCopy?.email?.length > 0 &&
      !filter.test(userDataOthersCopy?.email)
    ) {
      isValid = false;
      errorCopy.email = 'Please enter the valid mail';
    } else {
      errorCopy.email = '';
    }

    // Checking Mobile Number
    if (userDataOthersCopy?.mobile.length === 0) {
      isValid = false;
      errorCopy.mobile = 'Enter a valid 10 digit mobile number';
    } else if (userDataOthersCopy?.mobile.length < 9) {
      isValid = false;
      errorCopy.mobile = 'Enter a valid 10 digit mobile number';
    } else {
      errorCopy.mobile = '';
    }

    // Checking expert category
    if (!roleIDM_MasterData?.filter((val) => val?.selected)?.length > 0) {
      isValid = false;
      errorCopy.othersIdm_category = 'Select any of the role';
    } else {
      errorCopy.othersIdm_category = '';
    }
    if (!userDataOthersCopy?.category_id) {
      isValid = false;
      errorCopy.category_id = 'Please select the expert category';
    } else {
      errorCopy.category_id = '';
    }

    updateUserErrorOthers(errorCopy);
    return isValid;
  };

  // VALIDATE DOCTOR UPSERT DRAWER
  const isDoctorValidToSave = () => {
    const userDataDoctorCopy = JSON.parse(JSON.stringify(userDataDoctor));
    const errorCopy = JSON.parse(JSON.stringify(userDataDoctorError));
    let isValid = true;

    // checking name
    if (!userDataDoctorCopy?.name) {
      isValid = false;
      errorCopy.name = 'Please enter the name';
    } else {
      errorCopy.name = '';
    }

    // checking register_no
    if (!userDataDoctorCopy?.register_no) {
      isValid = false;
      errorCopy.register_no = 'Please enter the register no';
    } else {
      errorCopy.register_no = '';
    }

    // checking state_medical_council_name
    if (!userDataDoctorCopy?.state_medical_council_name) {
      isValid = false;
      errorCopy.state_medical_council_name =
        'Please enter the state medical council name';
    } else {
      errorCopy.state_medical_council_name = '';
    }

    // checking year_of_registration
    if (!userDataDoctorCopy?.year_of_registration) {
      isValid = false;
      errorCopy.year_of_registration = 'Please enter the year of registration';
    } else {
      errorCopy.year_of_registration = '';
    }

    if (!roleIDM_MasterData?.filter((val) => val?.selected)?.length > 0) {
      isValid = false;
      errorCopy.Idm_category = 'Select any of the role';
    } else {
      errorCopy.Idm_category = '';
    }

    // checking email
    const filter = /\S+@\S+\.\S+/;
    if (userDataDoctorCopy?.email?.length === 0) {
      isValid = false;
      errorCopy.email = 'Please enter the mail';
    } else if (
      userDataDoctorCopy?.email?.length > 0 &&
      !filter.test(userDataDoctorCopy?.email)
    ) {
      isValid = false;
      errorCopy.email = 'Please enter the valid mail';
    } else {
      errorCopy.email = '';
    }

    // Checking Mobile Number
    if (userDataDoctorCopy?.mobile.length === 0) {
      isValid = false;
      errorCopy.mobile = 'Enter a valid 10 digit mobile number';
    } else if (userDataDoctorCopy?.mobile.length < 9) {
      isValid = false;
      errorCopy.mobile = 'Enter a valid 10 digit mobile number';
    } else {
      errorCopy.mobile = '';
    }

    updateDoctorUserError(errorCopy);
    return isValid;
  };

  const handleDoctorNameChange = (key, value) => {
    updateDoctorUserData(key, value);
    if (doctorsaveBtn) isDoctorValidToSave();
  };

  const handleDoctorEmailChange = (key, value) => {
    updateDoctorUserData(key, value);
    if (doctorsaveBtn) isDoctorValidToSave();
  };

  const handleDoctoPhoneChange = (key, value) => {
    updateDoctorUserData(key, value);
    if (doctorsaveBtn) isDoctorValidToSave();
  };

  // expert handle change
  const expertNameChange = (key, value) => {
    updateUserData(key, value);
    if (expertsaveBtn) isIamValidToSave();
  };
  const expertEmailChange = (key, value) => {
    updateUserData(key, value);
    if (expertsaveBtn) isIamValidToSave();
  };
  const expertPhoneChange = (key, value) => {
    updateUserData(key, value);
    if (expertsaveBtn) isIamValidToSave();
  };

  // others handle changex

  const handleDisplaydata = (key, value) => {
    updateUserOthers(key, value);
    if (otherSaveBtn) isIamValidToSaveOthers();
  };

  const OtherNameChange = (key, value) => {
    updateUserOthers(key, value);
    if (otherSaveBtn) isIamValidToSaveOthers();
  };

  const otherEmailChange = (key, value) => {
    updateUserOthers(key, value);
    if (otherSaveBtn) isIamValidToSaveOthers();
  };

  const otherPhoneChange = (key, value) => {
    updateUserOthers(key, value);
    if (otherSaveBtn) isIamValidToSaveOthers();
  };

  const RecallFunction = () => {
    queryClient.invalidateQueries({
      queryKey: ['doctorUserList'],
    });

    initialDoctorUserGetData();
    setDoctorDrawerOpen(false);
    setIsApprove(false);
    clearDoctorUserData();
    setdoctorsaveBtn(false);
  };

  // UPSERT DOCTOR FUNCTION
  const upsertDoctorUserFnc = async (isApproved) => {
    const result = isDoctorValidToSave();
    setdoctorsaveBtn(true);
    // professional details upadte and Approve doctor

    //  Update and upsert doctor details
    if (result) {
      if (isApproved) {
        const reponse = await doctorApprove();
        if (reponse === 200) {
          RecallFunction();
        }
      } else {
        setdoctorsaveBtn(false);
        if (userDataDoctor?.id?.length > 0) {
          const reponse = await doctorUserUpdate();
          if (reponse?.code === 200) {
            RecallFunction();
          }
        } else {
          const reponse = await doctorUserUpsert();
          if (reponse?.code === 200) {
            RecallFunction();
          }
        }
      }
    }
  };

  // UPSERT EXPERT FUNCTION
  const upsertExpertUserFnc = async () => {
    const result = isIamValidToSave();
    setexpertsaveBtn(true);
    if (result) {
      if (userDataExpert?.id?.length > 0) {
        const reponse = await expertUserUpdate();

        if (reponse?.code === 200) {
          queryClient.invalidateQueries({
            queryKey: ['expertUserList'],
          });
          initialExpertUserGetData();
          setExpertDrawerOpen(false);
          clearUserData();
          setButtons(expertTypeData);
          setexpertsaveBtn(false);
        }
      } else {
        const reponse = await expertUserUpsert();
        if (reponse?.code === 200) {
          queryClient.invalidateQueries({
            queryKey: ['expertUserList'],
          });
          setExpertDrawerOpen(false);
          clearUserData();
          setButtons(expertTypeData);
          setexpertsaveBtn(false);
        }
      }
    }
  };

  // UPSERT OTHERS FUNCTION
  const upsertOthersUserFnc = async () => {
    const result = isIamValidToSaveOthers();
    setotherSaveBtn(true);
    if (result) {
      if (userDataOthers?.id?.length > 0) {
        const reponse = await OthersUserUpdate();

        if (reponse?.code === 200) {
          queryClient.invalidateQueries({
            queryKey: ['getOthersUser'],
          });
          initialOthersUserGetData();
          setOthersDrawerOpen(false);
          clearUserOthersData();
          // setRoleIdm(roleIDM_MasterData);
          setotherSaveBtn(false);
        }
      } else {
        const reponse = await othersUserUpsert();
        if (reponse?.code === 200) {
          queryClient.invalidateQueries({
            queryKey: ['getOthersUser'],
          });
           initialOthersUserGetData();
          setOthersDrawerOpen(false);
          clearUserOthersData();
          // setRoleIdm(roleIDM_MasterData);
          setotherSaveBtn(false);
        }
      }
    }
  };

  const handleSearch = async (value) => {
    // if (tab === 0) {
    //   setAdminSearch(value);
    //   queryClient.invalidateQueries({
    //     queryKey: ['adminUserList'],
    //   });
    //   const obj = {
    //     user_id: data?.id,
    //     search: value ?? '',
    //     limit: adminRowsPerPage,
    //     offset: adminOffset,
    //   };
    //   await getAdminUser(obj);
    // }
    if (tab === 0) {
      setDoctorSearch(value);
      queryClient.invalidateQueries({
        queryKey: ['doctorUserList'],
      });
      const obj = {
        user_id: data?.id,
        search: value ?? '',
        limit: doctorRowsPerPage,
        offset: doctorOffset,
      };
      await getDoctorUser(obj);
    } else if (tab === 1) {
      setExpertSearch(value);
      queryClient.invalidateQueries({
        queryKey: ['expertUserList'],
      });
      const obj = {
        user_id: data?.id,
        search: value ?? '',
        limit: expertRowsPerPage,
        offset: expertOffset,
      };
      await getExpertUser(obj);
    } else if (tab === 2) {
      setOthersSearch(value);
      queryClient.invalidateQueries({
        queryKey: ['getOthersUser'],
      });
      const obj = {
        user_id: data?.id,
        search: value ?? '',
        limit: othersRowsPerPage,
        offset: othersOffset,
      };
      await getOthersUser(obj);
    }
  };

  const resendInviteBtn = async (data) => {
    const obj = {
      user_id: data?.userId ?? '',
      user_profile_id: data?.user_profile_id ?? '',
      role_id: data?.role_id ?? 0,
    };
    queryClient.invalidateQueries({
      queryKey: ['user-resend-invite'],
    });
    await resendInvite(obj);
    queryClient.invalidateQueries({
      queryKey: ['doctorUserList'],
    });
    await initialDoctorUserGetData();
  };

  const onApproveFnc = async (row, val) => {
    if (tab === 0) {
      if (val) {
        setIsApprove(true);
        if (roleIDM_MasterData?.length > 0) {
          const arr = [];
          // eslint-disable-next-line array-callback-return
          roleIDM_MasterData?.map((v) => {
            const selectedCheck = row?.idm_role_id.find((va) => va === v.value);
            arr.push({ ...v, selected: selectedCheck ? true : false });
          });
          setRoleIdm(arr);
        }
        initialEditDoctorData(row);
        setDoctorDrawerOpen(true);
      } else {
        toast.error("Can't unApprove doctor");
      }
    } else if (tab === 1) {
      queryClient.invalidateQueries({
        queryKey: ['userVerify'],
      });
      const res = await userStatusUpadte(row, val);
      if (res === '200') {
        toast.success(
          val === true
            ? `${row?.fullName} Approved Successfully`
            : val === false
            ? `${row?.fullName} Un-Approved Successfully`
            : 'User Status Updated',
        );
        await initialExpertUserGetData();
      }
    } else if (tab === 2) {
      queryClient.invalidateQueries({
        queryKey: ['otherverify'],
      });
      const res = await userStatusOthers(row, val);
      if (res === 200) {
        toast.success(
          val === true
            ? `${row?.fullName} Approved Successfully`
            : val === false
            ? `${row?.fullName} Un-Approved Successfully`
            : 'User Status Updated',
        );
        queryClient.invalidateQueries({
          queryKey: ['getOthersUser'],
        });
        await initialOthersUserGetData();
      }
    }
  };

  const onActive = async (row, val) => {
    if (row?.statusId !== 4) {
      if (tab === 0) {
        queryClient.invalidateQueries({
          queryKey: ['userVerify'],
        });
        const res = await userActiveBlockUpdate(row, val === true ? 1 : 2);
        if (res === '200') {
          toast.success(
            val === true
              ? `${row?.fullName} Activated Successfully`
              : val === false
              ? `${row?.fullName} In-Activated Successfully`
              : 'User Status Updated',
          );
          queryClient.invalidateQueries({
            queryKey: ['doctorUserList'],
          });
          await initialDoctorUserGetData();
        }
      } else if (tab === 1) {
        queryClient.invalidateQueries({
          queryKey: ['userVerify'],
        });
        const res = await userActiveBlockUpdate(row, val === true ? 1 : 2);
        if (res === '200') {
          toast.success(
            val === true
              ? `${row?.fullName} Activated Successfully`
              : val === false
              ? `${row?.fullName} In-Activated Successfully`
              : 'User Status Updated',
          );
          queryClient.invalidateQueries({
            queryKey: ['expertUserList'],
          });
          await initialExpertUserGetData();
        }
      }
    } else {
      toast(`${row?.fullName} is still Invited Status. Please wait!`, {
        icon: '⚠️',
      });
    }
  };

  const onBlock = async (row, val) => {
    if (row?.statusId !== 4) {
      if (tab === 0) {
        queryClient.invalidateQueries({
          queryKey: ['userVerify'],
        });
        const res = await userActiveBlockUpdate(row, val === true ? 3 : 1);
        if (res === '200') {
          toast.success(
            val === true
              ? `${row?.fullName} Blocked Successfully`
              : val === false
              ? `${row?.fullName} Un-Blocked Successfully`
              : 'User Status Updated',
          );
          queryClient.invalidateQueries({
            queryKey: ['doctorUserList'],
          });
          await initialDoctorUserGetData();
        }
      } else if (tab === 1) {
        queryClient.invalidateQueries({
          queryKey: ['userVerify'],
        });
        const res = await userActiveBlockUpdate(row, val === true ? 3 : 1);
        if (res === '200') {
          toast.success(
            val === true
              ? `${row?.fullName} Blocked Successfully`
              : val === false
              ? `${row?.fullName} Un-Blocked Successfully`
              : 'User Status Updated',
          );
          queryClient.invalidateQueries({
            queryKey: ['expertUserList'],
          });
          await initialExpertUserGetData();
        }
      }
    } else {
      toast(`${row?.fullName} is still Invited Status. Please wait!`, {
        icon: '⚠️',
      });
    }
  };

  const handleChangePlan = async (type, value, data) => {
    const payload = {
      user_profile_id: data?.user_profile_id ?? '',
      appointment_plan_id:
        type === 'appointment' ? value : data?.appoinment_plan_id,
      followup_plan_id: type === 'followup' ? value : data?.followup_plan_id,
      chat_plan_id: type === 'chat' ? value : data?.chat_plan_id,
    };
    queryClient.invalidateQueries({
      queryKey: ['doctorPlanUpdate'],
    });
    const res = await doctorPlanUpdate(payload);

    if (res?.code === 200) {
      toast.success(
        type === 'appointment'
          ? `${data?.fullName} Appointment Plan Updated Successfully`
          : type === 'followup'
          ? `${data?.fullName} Followup Plan Updated Successfully`
          : type === 'chat'
          ? `${data?.fullName} Chat Plan Updated Successfully`
          : 'Doctor Plan Details Updated Successfully',
      );
      queryClient.invalidateQueries({
        queryKey: ['doctorUserList'],
      });
      await initialDoctorUserGetData();
    }
  };

  const onEdit = (data) => {
    setIsApprove(false);
    initialEditDoctorData(data);
    if (roleIDM_MasterData?.length > 0) {
      const arr = [];
      // eslint-disable-next-line array-callback-return
      roleIDM_MasterData?.map((v) => {
        const selectedCheck = data?.idm_role_id.find((va) => va === v.value);
        arr.push({ ...v, selected: selectedCheck ? true : false });
      });
      setRoleIdm(arr);
    }
    setDoctorDrawerOpen(true);
  };

  const onEditexpert = async (data) => {
    const res = await initialEditExpertData(data);
    setButtons(res);
    setExpertDrawerOpen(true);
  };

  const onEditOthers = async (data) => {
    initialEditOtherstData(data);
    if (data?.idm_role?.length > 0) {
      const arr = roleIDM_MasterData
        ?.filter((v) => v.name !== 'Doctor')
        ?.map((v) => {
          const selectedCheck = data?.idm_role_id.find((va) => va === v.value);
          return { ...v, selected: !!selectedCheck };
        });
    
      setRoleIdmOthers(arr);
    }
    setDisplayRole(displayRolesData);
    setOthersDrawerOpen(true);
  };

  const expertColumns = [
    {
      field: 'fullName',
      headerName: 'Expert Name',
      width: 200,
      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            textAlign: 'center',
          }}
        >
          {params?.value}
        </Typography>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 160,
    },
    {
      field: 'expert_category',
      headerName: 'Category',
      width: 160,
      renderCell: (params) =>
        params?.row?.expert_category.length > 0 &&
        params?.row?.expert_category?.map((val, i) => (
          <Typography fontSize={12} color="#888888" key={i} fontWeight={400}>
            {params?.row?.expert_category?.length === 3 ? (
              i === 0 ? (
                <Typography fontSize={12} color="#888888" fontWeight={400}>
                  {val?.label} ,&nbsp;
                </Typography>
              ) : i === 1 ? (
                <Typography fontSize={12} color="#888888" fontWeight={400}>
                  {val?.label} and&nbsp;
                </Typography>
              ) : (
                val?.label
              )
            ) : params?.row?.expert_category?.length === 2 ? (
              i === 0 ? (
                <Typography fontSize={12} color="#888888" fontWeight={400}>
                  {val?.label} and&nbsp;
                </Typography>
              ) : (
                val?.label
              )
            ) : (
              val?.label
            )}
          </Typography>
        )),
    },
    {
      field: 'isVerified',
      headerName: 'Approved',
      // type: 'number',
      width: 120,
      renderCell: (params) => (
        <Switch
          id="toggleApproved3"
          checked={params?.row?.isActive}
          onChange={(e) => onApproveFnc(params?.row, e.target.checked)}
          rootStyle={user_management_style.switchSx}
        />
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => (
        <Typography
          variant="body1"
          sx={{
            color: statusColor[params?.row?.statusId],
          }}
        >
          {params?.row?.status === 'In Active'
            ? 'Inactive'
            : params?.row?.status}
          {params?.row?.statusId === 4 && (
            <Tooltip title="Resend Invite">
              <IconButton
                color="secondary"
                onClick={() => resendInviteBtn(params?.row)}
              >
                <RefreshIcon
                  rootStyle={{ color: statusColor[params?.row?.statusId] }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 180,
      valueGetter: (params) =>
        getDateFormatToString(params?.row?.createdAt, 'lll'),
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      width: 180,
      valueGetter: (params) =>
        getDateFormatToString(params.row.updatedAt, 'lll'),
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => (
        <Box
          id="editexpert"
          sx={{ pl: 1 }}
          onClick={() => onEditexpert(params?.row)}
        >
          <EditIcon style={{ cursor: 'pointer', color: 'grey' }} />
        </Box>
      ),
    },
  ];

  const OthersColumns = [
    {
      field: 'fullName',
      headerName: 'Name',
      width: 200,
      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            textAlign: 'center',
          }}
        >
          {params?.value}
        </Typography>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 160,
    },
    {
      field: 'others_category',
      headerName: 'Category',
      width: 160,
      renderCell: (params) =>
        params?.row?.idm_role.length > 0 &&
        params?.row?.idm_role?.map((val, i) => (
          <Typography fontSize={12} color="#888888" key={i} fontWeight={400}>
            {params?.row?.idm_role?.length === 3 ? (
              i === 0 ? (
                <Typography fontSize={12} color="#888888" fontWeight={400}>
                  {val?.name} ,&nbsp;
                </Typography>
              ) : i === 1 ? (
                <Typography fontSize={12} color="#888888" fontWeight={400}>
                  {val?.name} and&nbsp;
                </Typography>
              ) : (
                val?.name
              )
            ) : params?.row?.idm_role?.length === 2 ? (
              i === 0 ? (
                <Typography fontSize={12} color="#888888" fontWeight={400}>
                  {val?.name} and&nbsp;
                </Typography>
              ) : (
                val?.name
              )
            ) : (
              val?.name
            )}
          </Typography>
        )),
    },
    {
      field: 'isVerified',
      headerName: 'Approved',
      // type: 'number',
      width: 120,
      renderCell: (params) => (
        <Switch
          id="toggleApproved3"
          checked={params?.row?.isActive}
          onChange={(e) => onApproveFnc(params?.row, e.target.checked)}
          rootStyle={user_management_style.switchSx}
        />
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => (
        <Typography
          variant="body1"
          sx={{
            color: statusColor[params?.row?.statusId],
          }}
        >
          {params?.row?.status === 'In Active'
            ? 'Inactive'
            : params?.row?.status}
          {params?.row?.statusId === 4 && (
            <Tooltip title="Resend Invite">
              <IconButton
                color="secondary"
                onClick={() => resendInviteBtn(params?.row)}
              >
                <RefreshIcon
                  rootStyle={{ color: statusColor[params?.row?.statusId] }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 180,
      valueGetter: (params) =>
        getDateFormatToString(params?.row?.createdAt, 'lll'),
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      width: 180,
      valueGetter: (params) =>
        getDateFormatToString(params.row.updatedAt, 'lll'),
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => (
        <Box
          id="editexpert"
          sx={{ pl: 1 }}
          onClick={() => onEditOthers(params?.row)}
        >
          <EditIcon style={{ cursor: 'pointer', color: 'grey' }} />
        </Box>
      ),
    },
  ];

  // const admimColumns = [
  //   {
  //     field: 'fullName',
  //     headerName: 'Name',
  //     width: 260,
  //     renderCell: (params) => (
  //       <Typography
  //         sx={{
  //           fontSize: '14px',
  //           fontWeight: 400,
  //           textAlign: 'center',
  //         }}
  //       >
  //         {params?.value} {data?.id === params?.row?.userId && '(You)'}
  //       </Typography>
  //     ),
  //   },
  //   {
  //     field: 'email',
  //     headerName: 'Email',
  //     width: 350,
  //   },
  //   {
  //     field: 'status',
  //     headerName: 'Status',
  //     width: 200,
  //     renderCell: (params) => (
  //       <Typography
  //         variant="body1"
  //         sx={{
  //           color: statusColor[params?.row?.statusId],
  //         }}
  //       >
  //         {params?.row?.status === 'In Active'
  //           ? 'Inactive'
  //           : params?.row?.status}
  //         {/* {params?.row?.statusId === 4 && (
  //           <Tooltip title="Resend Invite">
  //             <IconButton
  //               color="secondary"
  //               onClick={() => resendInviteBtn(params?.row)}
  //             >
  //               <RefreshIcon
  //                 rootStyle={{ color: statusColor[params?.row?.statusId] }}
  //               />
  //             </IconButton>
  //           </Tooltip>
  //         )} */}
  //       </Typography>
  //     ),
  //   },
  //   {
  //     field: 'createdAt',
  //     headerName: 'Created At',
  //     width: 200,
  //     valueGetter: (params) =>
  //       getDateFormatToString(params?.row?.createdAt, 'lll'),
  //   },
  //   {
  //     field: 'updatedAt',
  //     headerName: 'Updated At',
  //     width: 200,
  //     valueGetter: (params) =>
  //       getDateFormatToString(params?.row?.updatedAt, 'lll'),
  //   },
  // ];

  const doctorColumns = [
    {
      field: 'fullName',
      headerName: 'Doctor Name',
      width: 200,
      renderCell: (params) => (
        <>
          {params?.row?.role_id === 7 ? (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              sx={{
                border: '0px solid',
                backgroundColor: 'red',
              }}
              badgeContent={
                <Box sx={user_management_style.hcDoctorSx}>
                  {/* <HealthCirclesLogo
                    rootStyle={{ width: '0.7em', height: '0.7em' }}
                  /> */}
                  <Typography
                    sx={{
                      fontSize: '8px',
                      fontWeight: 700,
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#007965',
                      ml: '1px',
                      mt: '1px',
                    }}
                  >
                    CD
                  </Typography>
                </Box>
              }
            >
              <Avatar
                sx={user_management_style.profileSx}
                alt="profile_pic"
                src={params?.row?.profile_pic}
              >
                {params?.value?.slice(0, 1) ?? 'A'}
              </Avatar>
            </Badge>
          ) : params?.row?.is_health_circles_doctor ? (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              badgeContent={
                <Box sx={user_management_style.hcDoctorSx}>
                  <HealthCirclesLogo
                    rootStyle={{ width: '0.7em', height: '0.7em' }}
                  />
                </Box>
              }
            >
              <Avatar
                sx={user_management_style.profileSx}
                alt="profile_pic"
                src={params?.row?.profile_pic}
              >
                {params?.value?.slice(0, 1) ?? 'A'}
              </Avatar>
            </Badge>
          ) : (
            <Avatar
              sx={user_management_style.profileSx}
              alt="profile_pic"
              src={params?.row?.profile_pic}
            >
              {params?.value?.slice(0, 1) ?? 'A'}
            </Avatar>
          )}
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              marginLeft: '20px',
            }}
          >
            {params?.value}
          </Typography>
        </>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 240,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 160,
    },
    {
      field: 'appoinment_plan_id',
      headerName: 'Appointment Plan',
      width: 320,
      renderCell: (params) => {
        return selectBoxIsLoading ? (
          <Skeleton variant="text" width="100%" />
        ) : (
          params?.row?.role_id !== 7 && (
            <Dropdown
              selectOption={
                Array.isArray(appointmentPlanManagementData) &&
                appointmentPlanManagementData?.length > 0
                  ? appointmentPlanManagementData
                  : []
              }
              id="appointmentPlan"
              datatestid="appointmentPlan"
              placeholder="Appointment Plan"
              value={params?.row?.appoinment_plan_id}
              // disabled={params?.row?.role_id === 7 ? true : false}
              onChange={(value) => {
                handleChangePlan(
                  'appointment',
                  value?.target?.value,
                  params?.row,
                );
              }}
            />
          )
        );
      },
    },
    {
      field: 'followup_plan_id',
      headerName: 'Followup Plan',
      width: 250,
      renderCell: (params) => {
        return selectBoxIsLoading ? (
          <Skeleton variant="text" width="100%" />
        ) : (
          params?.row?.role_id !== 7 && (
            <Dropdown
              selectOption={
                Array.isArray(followupPlanManagementData) &&
                followupPlanManagementData?.length > 0
                  ? followupPlanManagementData
                  : []
              }
              id="followupPlan"
              datatestid="followupPlan"
              placeholder="Followup Plan"
              value={params?.row?.followup_plan_id}
              // disabled={params?.row?.role_id === 7 ? true : false}
              onChange={(value) => {
                handleChangePlan('followup', value?.target?.value, params?.row);
              }}
            />
          )
        );
      },
    },
    {
      field: 'chat_plan_id',
      headerName: 'Chat Plan',
      width: 250,
      renderCell: (params) => {
        return selectBoxIsLoading ? (
          <Skeleton variant="text" width="100%" />
        ) : (
          params?.row?.role_id !== 7 && (
            <Dropdown
              selectOption={
                Array.isArray(chatPlanManagementData) &&
                chatPlanManagementData?.length > 0
                  ? chatPlanManagementData
                  : []
              }
              id="chatPlan"
              datatestid="chatPlan"
              placeholder="Chat Plan"
              value={params?.row?.chat_plan_id}
              // disabled={params?.row?.role_id === 7 ? true : false}
              onChange={(value) => {
                handleChangePlan('chat', value?.target?.value, params?.row);
              }}
            />
          )
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => (
        <Typography
          variant="body1"
          sx={{
            color: statusColor[params?.row?.statusId],
          }}
        >
          {params?.row?.status === 'In Active'
            ? 'Inactive'
            : params?.row?.status}
          {params?.row?.statusId === 4 && (
            <Tooltip title="Resend Invite">
              <IconButton
                color="secondary"
                onClick={() => resendInviteBtn(params?.row)}
              >
                <RefreshIcon
                  rootStyle={{ color: statusColor[params?.row?.statusId] }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      ),
    },
    {
      field: 'statusId',
      headerName: 'Active',
      width: 100,
      renderCell: (params) => (
        <Switch
          id="toggle5"
          checked={params?.row?.statusId === 1}
          onChange={(e) => onActive(params?.row, e.target.checked)}
          rootStyle={user_management_style.switchSx}
        />
      ),
    },
    {
      field: 'statusIdd',
      headerName: 'Block',
      width: 100,
      renderCell: (params) => (
        <Switch
          id="block5"
          checked={params?.row?.statusIdd === 3}
          onChange={(e) => onBlock(params?.row, e.target.checked)}
          rootStyle={user_management_style.switchSx}
        />
      ),
    },
    {
      field: 'isVerified',
      headerName: 'Approved',
      width: 100,
      renderCell: (params) => (
        <Switch
          id="approval5"
          checked={params?.row?.isActive}
          onChange={(e) => onApproveFnc(params?.row, e.target.checked)}
          rootStyle={user_management_style.switchSx}
        />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 180,
      valueGetter: (params) =>
        getDateFormatToString(params?.row?.createdAt, 'lll'),
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      width: 180,
      valueGetter: (params) =>
        getDateFormatToString(params?.row?.updatedAt, 'lll'),
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => (
        <Box id="edit5" sx={{ pl: 1 }} onClick={() => onEdit(params?.row)}>
          <EditIcon style={{ cursor: 'pointer', color: 'grey' }} />
        </Box>
      ),
    },
  ];

  const handleTabChange = async (value) => {
    setTab(value);
    if (value === 0) {
      await getIdmRoles(true);
      // await initialAdminUserGetData();
      await initialDoctorUserGetData();
      await getPlanManagementData();
    } else if (value === 1) {
      await initialExpertUserGetData();
    } else if (value === 2) {
      await getIdmRoles(false);
      await getDisplayRoles();
      await initialOthersUserGetData();
    }
  };

  // ---------------------- ADMIN PAGINATION--------------------------------
  // const onAdminLimitChange = async (limit, offset) => {
  //   setAdminRowsPerPage(limit);
  //   setAdminOffset(offset);
  //   queryClient.invalidateQueries({
  //     queryKey: ['adminUserList'],
  //   });

  //   const obj = {
  //     user_id: data?.id,
  //     search: adminSearch ?? '',
  //     limit,
  //     offset,
  //   };
  //   await getAdminUser(obj);
  // };

  // const onAdminOffsetchange = async (offset) => {
  //   setAdminOffset(offset);
  //   queryClient.invalidateQueries({
  //     queryKey: ['adminUserList'],
  //   });

  //   const obj = {
  //     user_id: data?.id,
  //     search: adminSearch ?? '',
  //     limit: adminRowsPerPage,
  //     offset,
  //   };
  //   await getAdminUser(obj);
  // };

  // ---------------------- EXPERT PAGINATION--------------------------------
  const onExpertLimitChange = async (limit, offset) => {
    setExpertRowsPerPage(limit);
    setExpertOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['expertUserList'],
    });

    const obj = {
      user_id: data?.id,
      search: expertSearch ?? '',
      limit,
      offset,
    };
    await getExpertUser(obj);
  };

  const onExpertOffsetchange = async (offset) => {
    setExpertOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['expertUserList'],
    });

    const obj = {
      user_id: data?.id,
      search: expertSearch ?? '',
      limit: expertRowsPerPage,
      offset,
    };
    await getExpertUser(obj);
  };

  // ---------------------- OTHERS PAGINATION--------------------------------
  const onOthersLimitChange = async (limit, offset) => {
    setOthersRowsPerPage(limit);
    setOthersOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['expertUserList'],
    });

    const obj = {
      user_id: data?.id,
      search: expertSearch ?? '',
      limit,
      offset,
    };
    await getOthersUser(obj);
  };

  const onOthersOffsetchange = async (offset) => {
    setOthersOffset(offset);

    queryClient.invalidateQueries({
      queryKey: ['expertUserList'],
    });

    const obj = {
      user_id: data?.id,
      search: expertSearch ?? '',
      limit: expertRowsPerPage,
      offset,
    };
    await getOthersUser(obj);
  };

  // ---------------------- DOCTOR PAGINATION--------------------------------
  const onDoctorLimitChange = async (limit, offset) => {
    setDoctorRowsPerPage(limit);
    setDoctorOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['doctorUserList'],
    });

    const obj = {
      user_id: data?.id,
      search: doctorSearch ?? '',
      limit,
      offset,
    };
    await getDoctorUser(obj);
  };

  const onDoctorOffsetchange = async (offset) => {
    setDoctorOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['doctorUserList'],
    });

    const obj = {
      user_id: data?.id,
      search: doctorSearch ?? '',
      limit: doctorRowsPerPage,
      offset,
    };
    await getDoctorUser(obj);
  };

  const initialData = async () => {
    await initialDoctorUserGetData();
    await getPlanManagementData();
  };

  useEffect(() => {
    // initialAdminUserGetData();
    initialData();
    localStorage.setItem(localStorageKeys.backOfficeVersion, version);
  }, []);

  return (
    <Box
      className={`${className}`}
      {...rest}
      px={3}
      py={3}
      sx={{ height: '100vh', overflowY: 'scroll', backgroundColor: '#F8F8F9' }}
    >
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <UserManagementTopContainer
              handleSearch={handleSearch}
              searchValue={
                tab === 0
                  ? // ? adminSearch
                    doctorSearch
                  : tab === 1
                  ? expertSearch
                  : tab === 2
                  ? othersSearch
                  : ''
              }
              handleAddClick={() => handleAddClick()}
              title="User"
              count={
                tab === 0
                  ? // ? userManagementState?.adminUserData?.count
                    userManagementState?.doctorUserData?.count
                  : tab === 1
                  ? userManagementState?.expertUserData?.count
                  : tab === 2
                  ? userManagementState?.OthersUserData?.count
                  : 0
              }
              showSearchField
              // searchFieldPlaceholder={
              //   tab === 0
              //     ? 'Search Members by Name & Email'
              //     : 'Search Members by Name, Phone & Email'
              // }
              // showAddButton={tab === 0 ? false : true}
              addButtonName={
                // tab === 2 ? 'Add Expert' : tab === 1 ? 'Add Doctor' : 'Add User'
                tab === 2
                  ? 'Add Others'
                  : tab === 1
                  ? 'Add Expert'
                  : 'Add Doctor'
              }
              currentTab={handleTabChange}
            />
          </Grid>

          {/* ADMIN TABLE */}
          {/* {tab === 0 && (
            <Grid item xs={12}>
              {loading ? (
                <Box>
                  <TableSkeletonLoader />
                </Box>
              ) : (
                <MasterTable
                  columns={admimColumns}
                  data={adminUserData?.data ?? []}
                  customTableStyle={user_management_style.dataTableCustomSx}
                  hideFooter
                  rootStyle={{ height: '68vh' }}
                />
              )}
              <Stack
                flexDirection="row"
                justifyContent="end"
                sx={{ width: '100%' }}
              >
                <Pagination
                  totalCount={adminUserData?.count ?? 0}
                  onLimitChange={onAdminLimitChange}
                  onOffsetchange={onAdminOffsetchange}
                  offset={adminOffset}
                  rowsPerPage={adminRowsPerPage}
                />
              </Stack>
            </Grid>
          )} */}

          {/* DOCTOR TABLE */}
          {tab === 0 && (
            <Grid item xs={12}>
              {loading ? (
                <Box>
                  <TableSkeletonLoader />
                </Box>
              ) : (
                <MasterTable
                  columns={doctorColumns}
                  data={doctorUserData?.data ?? []}
                  customTableStyle={user_management_style.dataTableCustomSx}
                  hideFooter
                  rootStyle={{ height: '68vh' }}
                />
              )}
              <Stack
                flexDirection="row"
                justifyContent="end"
                sx={{ width: '100%' }}
              >
                <Pagination
                  totalCount={doctorUserData?.count ?? 0}
                  onLimitChange={onDoctorLimitChange}
                  onOffsetchange={onDoctorOffsetchange}
                  offset={doctorOffset}
                  rowsPerPage={doctorRowsPerPage}
                />
              </Stack>
            </Grid>
          )}

          {/* EXPERT TABLE */}
          {tab === 1 && (
            <Grid item xs={12}>
              {loading ? (
                <Box>
                  <TableSkeletonLoader />
                </Box>
              ) : (
                <MasterTable
                  columns={expertColumns}
                  data={expertUserData?.data ?? []}
                  customTableStyle={user_management_style.dataTableCustomSx}
                  hideFooter
                  rootStyle={{ height: '68vh' }}
                />
              )}
              <Stack
                flexDirection="row"
                justifyContent="end"
                sx={{ width: '100%' }}
              >
                <Pagination
                  totalCount={expertUserData?.count ?? 0}
                  onLimitChange={onExpertLimitChange}
                  onOffsetchange={onExpertOffsetchange}
                  offset={expertOffset}
                  rowsPerPage={expertRowsPerPage}
                />
              </Stack>
            </Grid>
          )}

          {/* Others TABLE */}
          {tab === 2 && (
            <Grid item xs={12}>
              {loading ? (
                <Box>
                  <TableSkeletonLoader />
                </Box>
              ) : (
                <MasterTable
                  columns={OthersColumns}
                  data={OthersUserData?.data ?? []}
                  customTableStyle={user_management_style.dataTableCustomSx}
                  hideFooter
                  rootStyle={{ height: '68vh' }}
                />
              )}
              <Stack
                flexDirection="row"
                justifyContent="end"
                sx={{ width: '100%' }}
              >
                <Pagination
                  totalCount={OthersUserData?.count ?? 0}
                  onLimitChange={onOthersLimitChange}
                  onOffsetchange={onOthersOffsetchange}
                  offset={othersOffset}
                  rowsPerPage={othersRowsPerPage}
                />
              </Stack>
            </Grid>
          )}
        </Grid>

        {/* Add Doctor Drawer */}
        {tab === 0 && (
          <Box>
            <UserManagementDrawer
              isUpdate={userDataDoctor?.id?.length > 0 ? true : false}
              drawerOpen={doctorDrawerOpen}
              loading={loading}
              custom={isApprove ? 'Approve' : ''}
              header={isApprove ? 'Doctor Approval' : 'Doctor User'}
              closeDrawer={closeDoctorDrawer}
              upsertUser={() => upsertDoctorUserFnc(isApprove)}
              children={
                <Box width={328} pb={11}>
                  <Grid>
                    <Grid pb={1.5} item md={12} lg={12} xl={12}>
                      <Label isRequired htmlFor="name">
                        Name
                      </Label>
                      <Input
                        id="name"
                        placeholder="User name"
                        isError={userDataDoctorError?.name?.length > 0}
                        errorMessage={userDataDoctorError?.name}
                        onChange={(event) =>
                          handleDoctorNameChange('name', event?.target.value)
                        }
                        value={userDataDoctor?.name}
                      />
                    </Grid>
                    <Grid pb={1.5} item md={12} lg={12} xl={12}>
                      <Label isRequired htmlFor="email">
                        Email
                      </Label>
                      <Input
                        id="email"
                        placeholder="Email"
                        isError={userDataDoctorError?.email?.length > 0}
                        errorMessage={userDataDoctorError?.email}
                        onChange={(event) =>
                          handleDoctorEmailChange('email', event?.target.value)
                        }
                        value={userDataDoctor?.email}
                        isReadOnly={
                          userDataDoctor?.id?.length > 0 ? true : false
                        }
                      />
                    </Grid>
                    <Grid pb={1.5} item md={12} lg={12} xl={12}>
                      <Label isRequired>Mobile Number</Label>
                      <MobileInput
                        id="mobileNumber"
                        value={{
                          mobile_code: userDataDoctor?.country_code ?? '+91',
                          mobile: userDataDoctor?.mobile ?? '',
                        }}
                        isError={userDataDoctorError?.mobile?.length > 0}
                        helperText={userDataDoctorError?.mobile}
                        onChange={(value) =>
                          handleDoctoPhoneChange('mobileNumber', value)
                        }
                        disabled={userDataDoctor?.id?.length > 0 ? true : false}
                        isReadonlyCC={
                          userDataDoctor?.id?.length > 0 ? true : false
                        }
                      />
                    </Grid>
                    <Grid pb={1.5} item md={12} lg={12} xl={12}>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                      >
                        <CheckBox
                          id="circle"
                          checked={userDataDoctor?.is_health_circles_doctor}
                          disabled={
                            userDataDoctor?.id?.length > 0 ? true : false
                          }
                          checkSecondStyle={
                            userDataDoctor?.id?.length > 0
                              ? user_management_style.checkSecondDisabledStyle
                              : ''
                          }
                          onChange={(e) => {
                            updateDoctorUserData(
                              'is_health_circles_doctor',
                              e.target.checked,
                            );
                          }}
                        />
                        <Typography
                          sx={{ cursor: 'pointer', fontSize: '14px' }}
                        >
                          Belongs to Our Circle
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid pb={1.5} item md={12} lg={12} xl={12}>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                      >
                        <CheckBox
                          id="chiefDoct"
                          checked={userDataDoctor?.is_chief_doctor}
                          disabled={
                            userDataDoctor?.id?.length > 0 ? true : false
                          }
                          checkSecondStyle={
                            userDataDoctor?.id?.length > 0
                              ? user_management_style.checkSecondDisabledStyle
                              : ''
                          }
                          onChange={(e) => {
                            updateDoctorUserData(
                              'is_chief_doctor',
                              e.target.checked,
                            );
                          }}
                        />
                        <Typography
                          sx={{ cursor: 'pointer', fontSize: '14px' }}
                        >
                          Chief Doctor
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid pb={1.5} item md={12} lg={12} xl={12}>
                      <Label isRequired htmlFor="register_no">
                        Register No
                      </Label>
                      <Input
                        id="register_no"
                        placeholder="Register No"
                        isError={userDataDoctorError?.register_no?.length > 0}
                        errorMessage={userDataDoctorError?.register_no}
                        onChange={(event) =>
                          handleDoctorNameChange(
                            'register_no',
                            event?.target.value,
                          )
                        }
                        value={userDataDoctor?.register_no}
                        disabled={
                          isApprove
                            ? false
                            : userDataDoctor?.id?.length > 0
                            ? true
                            : false
                        }
                      />
                    </Grid>
                    <Grid pb={1.5} item md={12} lg={12} xl={12}>
                      <Label isRequired htmlFor="state_medical_council_name">
                        State Medical Council Name
                      </Label>
                      <Input
                        id="state_medical_council_name"
                        placeholder="State Medical Council Name"
                        isError={
                          userDataDoctorError?.state_medical_council_name
                            ?.length > 0
                        }
                        errorMessage={
                          userDataDoctorError?.state_medical_council_name
                        }
                        onChange={(event) =>
                          handleDoctorNameChange(
                            'state_medical_council_name',
                            event?.target.value,
                          )
                        }
                        value={userDataDoctor?.state_medical_council_name}
                        disabled={
                          isApprove
                            ? false
                            : userDataDoctor?.id?.length > 0
                            ? true
                            : false
                        }
                      />
                    </Grid>
                    <Grid pb={1.5} item md={12} lg={12} xl={12}>
                      <Label isRequired htmlFor="year_of_registration">
                        Year of Registration
                      </Label>
                      <Input
                        id="year_of_registration"
                        placeholder="Year of Registration"
                        isError={
                          userDataDoctorError?.year_of_registration?.length > 0
                        }
                        errorMessage={userDataDoctorError?.year_of_registration}
                        onChange={(event) =>
                          handleDoctorNameChange(
                            'year_of_registration',
                            event?.target.value,
                          )
                        }
                        value={userDataDoctor?.year_of_registration}
                        disabled={
                          isApprove
                            ? false
                            : userDataDoctor?.id?.length > 0
                            ? true
                            : false
                        }
                      />
                    </Grid>
                    {!userDataDoctor?.is_chief_doctor && (
                      <Grid pb={1.5} item md={12} lg={12} xl={12}>
                        <Label isRequired>Role (IDM)</Label>
                        <MultiSelectToggleButton
                          buttons={roleIdm ?? []}
                          handleButton={handleIDMButton}
                          buttonactiveStyle={
                            user_management_style.togglebuttonactiveSX
                          }
                          buttoninactiveStyle={
                            user_management_style.togglebuttoninactiveSX
                          }
                          disabled={isApprove ? true : false}
                        />

                        <Typography color="#F44F5A" mt={0.5} fontSize={12}>
                          {userDataDoctorError?.Idm_category}
                        </Typography>
                        {/* )} */}
                      </Grid>
                    )}
                  </Grid>
                </Box>
              }
            />
          </Box>
        )}

        {/* Add Expert Drawer */}
        {tab === 1 && (
          <Box>
            <UserManagementDrawer
              isUpdate={userDataExpert?.id?.length > 0 ? true : false}
              drawerOpen={expertDrawerOpen}
              loading={loading}
              header="Expert User"
              closeDrawer={closeExpertDrawer}
              upsertUser={upsertExpertUserFnc}
              children={
                <Box width={328}>
                  <Grid>
                    <Grid pb={1.5} item md={12} lg={12} xl={12}>
                      <Label isRequired htmlFor="name">
                        Name
                      </Label>
                      <Input
                        id="name"
                        placeholder="User name"
                        isError={userDataExpertError?.name?.length > 0}
                        errorMessage={userDataExpertError?.name}
                        onChange={(event) =>
                          expertNameChange('name', event?.target.value)
                        }
                        value={userDataExpert?.name}
                      />
                    </Grid>
                    <Grid pb={1.5} item md={12} lg={12} xl={12}>
                      <Label isRequired htmlFor="email">
                        Email
                      </Label>
                      <Input
                        id="email"
                        placeholder="Email"
                        isError={userDataExpertError?.email?.length > 0}
                        errorMessage={userDataExpertError?.email}
                        onChange={(event) =>
                          expertEmailChange('email', event?.target.value)
                        }
                        value={userDataExpert?.email}
                        isReadOnly={
                          userDataExpert?.id?.length > 0 ? true : false
                        }
                      />
                    </Grid>
                    <Grid pb={1.5} item md={12} lg={12} xl={12}>
                      <Label isRequired>Mobile Number</Label>
                      <MobileInput
                        value={{
                          mobile_code: userDataExpert?.country_code ?? '+91',
                          mobile: userDataExpert?.mobile ?? '',
                        }}
                        isError={userDataExpertError?.mobile?.length > 0}
                        helperText={userDataExpertError?.mobile}
                        onChange={(value) =>
                          expertPhoneChange('mobileNumber', value)
                        }
                        disabled={userDataExpert?.id?.length > 0 ? true : false}
                        isReadonlyCC={
                          userDataExpert?.id?.length > 0 ? true : false
                        }
                      />
                    </Grid>
                    <Grid pb={1.5} item md={12} lg={12} xl={12}>
                      <Label isRequired>Expert type</Label>
                      <MultiSelectToggleButton
                        buttons={buttons}
                        handleButton={handleButton}
                        buttonactiveStyle={
                          user_management_style.togglebuttonactiveSX
                        }
                        buttoninactiveStyle={
                          user_management_style.togglebuttoninactiveSX
                        }
                        disabled={userDataExpert?.id?.length > 0 ? true : false}
                      />

                      <Typography color="#F44F5A" mt={0.5} fontSize={12}>
                        {userDataExpertError?.expert_category}
                      </Typography>
                      {/* )} */}
                    </Grid>
                  </Grid>
                </Box>
              }
            />
          </Box>
        )}

        {tab === 2 && (
          <Box>
            <UserManagementDrawer
              isUpdate={userDataOthers?.id?.length > 0 ? true : false}
              drawerOpen={othersDrawerOpen}
              loading={loading}
              header="Other User"
              closeDrawer={closeOthersDrawer}
              upsertUser={upsertOthersUserFnc}
              children={
                <Box width={328}>
                  <Grid>
                    <Grid pb={1.5} item md={12} lg={12} xl={12}>
                      <Label isRequired htmlFor="name">
                        Name
                      </Label>
                      <Input
                        id="name"
                        placeholder="User name"
                        isError={userDataOthersError?.name?.length > 0}
                        errorMessage={userDataOthersError?.name}
                        onChange={(event) =>
                          OtherNameChange('name', event?.target.value)
                        }
                        value={userDataOthers?.name}
                      />
                    </Grid>
                    <Grid pb={1.5} item md={12} lg={12} xl={12}>
                      <Label isRequired htmlFor="email">
                        Email
                      </Label>
                      <Input
                        id="email"
                        placeholder="Email"
                        isError={userDataOthersError?.email?.length > 0}
                        errorMessage={userDataOthersError?.email}
                        onChange={(event) =>
                          otherEmailChange('email', event?.target.value)
                        }
                        value={userDataOthers?.email}
                        isReadOnly={
                          userDataOthers?.id?.length > 0 ? true : false
                        }
                      />
                    </Grid>
                    <Grid pb={1.5} item md={12} lg={12} xl={12}>
                      <Label isRequired>Mobile Number</Label>
                      <MobileInput
                        value={{
                          mobile_code: userDataOthers?.country_code ?? '+91',
                          mobile: userDataOthers?.mobile ?? '',
                        }}
                        isError={userDataOthersError?.mobile?.length > 0}
                        helperText={userDataOthersError?.mobile}
                        onChange={(value) =>
                          otherPhoneChange('mobileNumber', value)
                        }
                        disabled={userDataOthers?.id?.length > 0 ? true : false}
                        isReadonlyCC={
                          userDataOthers?.id?.length > 0 ? true : false
                        }
                      />
                    </Grid>

                    <Grid pb={1.5} item md={12} lg={12} xl={12}>
                      <Label isRequired>Role (IDM)</Label>
                      <MultiSelectToggleButton
                        buttons={roleIdmOthers ?? []}
                        handleButton={handleOtherIDMButton}
                        buttonactiveStyle={
                          user_management_style.togglebuttonactiveSX
                        }
                        buttoninactiveStyle={
                          user_management_style.togglebuttoninactiveSX
                        }
                        // disabled={userDataOthers?.id?.length > 0 ? true : false}
                      />

                      <Typography color="#F44F5A" mt={0.5} fontSize={12}>
                        {userDataOthersError?.othersIdm_category}
                      </Typography>
                      {/* )} */}
                    </Grid>
                    <Grid pb={1.5} item md={12} lg={12} xl={12}>
                      <Label isRequired>Role (Display)</Label>
                      <ToggleButton
                        RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                        isError={userDataOthersError?.category_id?.length > 0}
                        errorMessage={userDataOthersError?.category_id}
                        options={displayRole}
                        value={userDataOthers.category_id}
                        buttonactiveStyle={
                          user_management_style.togglebuttonactiveSX
                        }
                        buttoninactiveStyle={
                          user_management_style.togglebuttoninactiveSX
                        }
                        onChange={(value) =>
                          handleDisplaydata('category_id', value)
                        }
                        disabled={userDataOthers?.id?.length > 0 ? true : false}
                      />{' '}
                    </Grid>
                  </Grid>
                </Box>
              }
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
UserManagement.propTypes = {
  className: PropTypes.string,
};
