import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Button, Drawer } from '../../../atoms';
import { userManagementDrawer_style } from './style';

function UserManagementDrawer(props) {
  const {
    className = '',
    isUpdate = false,
    header = '',
    drawerOpen,
    closeDrawer = () => {},
    upsertUser = () => {},
    children,
    loading,
    rootStyle = {},
    custom,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...userManagementDrawer_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box>
        {drawerOpen && (
          <Drawer
            show={drawerOpen}
            onCloseDrawer={() => closeDrawer()}
            anchor="right"
            isCloseIconRequired
            header={header}
            headerStyle={{
              fontSize: '16px',
              fontWeight: 600,
              color: '#101010',
              textTransform: 'capitalize',
            }}
            footerStyle={{ py: 2.8, px: 2 }}
            footer={
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  loading={loading}
                  buttonStyle={{
                    bgcolor: 'primary.main',
                    textTransform: 'capitalize',
                    color: '#fff',
                    borderRadius: '8px',
                    '&:hover': {
                      bgcolor: 'primary.main',
                    },
                  }}
                  onClick={() => upsertUser()}
                >
                  {isUpdate ? (custom ? custom : 'update') : 'Save'}
                </Button>
                <Button
                  disabled={loading === true ? true : false}
                  buttonStyle={{
                    border: '1px solid',
                    borderColor: 'primary.main',
                    color: 'primary.main',
                    textTransform: 'capitalize',
                    borderRadius: '8px',
                    bgcolor: '#fff',
                    '&:hover': {
                      bgcolor: 'primaryTints.200',
                    },
                  }}
                  onClick={closeDrawer}
                >
                  Cancel
                </Button>
              </Box>
            }
          >
            {children}
          </Drawer>
        )}
      </Box>
    </Box>
  );
}

UserManagementDrawer.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  rootStyle: PropTypes.object,
  drawerOpen: PropTypes.bool,
  closeDrawer: PropTypes.func,
  upsertUser: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.element,
  isUpdate: PropTypes.bool,
};

export { UserManagementDrawer };

